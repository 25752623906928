import React, {useContext, useEffect, useState} from "react";
import {Dropdown, Header, Icon, Loader, Segment, Table,} from "semantic-ui-react";

import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import {useAuth0} from "../../../react-auth0-spa";
import {Pagination, PaginationPageSettings} from "../../../UI-components";
import {helperGetDateString} from "../../Functions/DateFunctions";
import {fetchAgentsAttendances} from "../../Functions/GetAgents";
import DateSelectors from "../DateSelectors/DateSelectors";
import SearchComponent from "../SearchComponent/SearchComponent";

import {initDescriptionOptions, initProcessOptions, searchOptions, stateOptions,} from "./constants";
import {baseAttendancesFilter, fillContentToOptions, helperGetTimeString,} from "./utils";

import "./Attandance.css";
import {downloadAgentActivityReport} from "../../../apis/serviceApis";

const Attendance = (props) => {
  const [attendances, setAttendances] = useState([]);
  const [, setNotFilteredAttendances] = useState([]);
  const {localTimezone} = useContext(ClientContext);
  const [filteredAttendances, setFilteredAttendances] = useState([]);
  const [sortedAttendances, setSortedAttendances] = useState([]);
  const [orderedByArrow, setOrderedByArrow] = useState("Date");
  const [isLoading, setIsLoading] = useState(true);
  const {getTokenSilently} = useAuth0();
  const [descriptionOptions, setDescriptionOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const env = useContext(EnvContext);

  const [attendancesByPage, setAttendancesByPage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const onChangeMultiselect = (setOption) => (e, data) => {
    e.stopPropagation();
    setOption((options) => {
      const index = options.findIndex(({key}) => key === data.children.key);
      const newOptions = [...options];
      newOptions[index] = {
        ...newOptions[index],
        checked: !newOptions[index].checked,
      };
      return fillContentToOptions(newOptions);
    });
  };

  useEffect(() => {
    setDescriptionOptions(fillContentToOptions(initDescriptionOptions));
    setProcessOptions(fillContentToOptions(initProcessOptions));
  }, []);

  useEffect(() => {
    if (filteredAttendances.length > 0) sortConversations();
    else setSortedAttendances([]);
  }, [orderedByArrow, filteredAttendances]);

  useEffect(() => {
    const availableDescriptions = descriptionOptions
      .filter(({checked}) => checked)
      .map(({value}) => value);
    const availableProcesses = processOptions
      .filter(({checked}) => checked)
      .map(({value}) => value);
    if (!availableProcesses.length || !availableDescriptions.length) {
      setFilteredAttendances([]);
    } else {
      if (attendances && attendances.length > 0) {
        setFilteredAttendances(
          attendances.filter((attendance) => {
            const description = attendance.description;
            const process = attendance.process;
            return (
              availableDescriptions.find((descr) => descr === description) &&
              availableProcesses.find((pr) => pr === process)
            );
          })
        );
      }
    }
  }, [attendances, processOptions, descriptionOptions]);

  const ToDateDropdownFunc = async (fromDate, toDate, option) => {
    setSortedAttendances([]);
    setIsLoading(true);
    const agentsAttendances = await fetchAgentsAttendances(
      getTokenSilently,
      env,
      props.match.params.client,
      null,
      fromDate,
      toDate
    );
    setPageNumber(1);
    setNotFilteredAttendances(agentsAttendances);
    setAttendances(baseAttendancesFilter(agentsAttendances));
    setIsLoading(false);
  };

  const setSortedAttendancesOrNull = (valueToinsert) => {
    if (valueToinsert.length > 0) setSortedAttendances(valueToinsert);
    else setSortedAttendances(null);
  };

  const exportAttendance = async (fromDate, toDate) => {
    const token = await getTokenSilently();

    const params = {
      clientName: client,
      timeZone: localTimezone,
      conversionPeriod: 0,
      startDate: new Date(fromDate).toLocaleDateString("en-UK"),
      endDate: new Date(toDate).toLocaleDateString("en-UK"),
    };
    await downloadAgentActivityReport(params, token, env);
  };

  const sortConversations = () => {
    let sortedAttendances;
    switch (orderedByArrow) {
      case "Agent name up":
        sortedAttendances = [...filteredAttendances].sort(function (a, b) {
          if (a.name < b.name) {
            return 1;
          }
          if (a.name > b.name) {
            return -1;
          }
          return 0;
        });
        setSortedAttendancesOrNull(sortedAttendances);
        break;
      case "Agent name":
        sortedAttendances = [...filteredAttendances].sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setSortedAttendancesOrNull(sortedAttendances);
        break;
      case "Date up":
        sortedAttendances = [...filteredAttendances].sort(function (a, b) {
          return new Date(b.ts) - new Date(a.ts);
        });
        setSortedAttendancesOrNull(sortedAttendances);
        break;
      case "Date":
      default:
        sortedAttendances = [...filteredAttendances].sort(function (a, b) {
          return new Date(a.ts) - new Date(b.ts);
        });
        setSortedAttendancesOrNull(sortedAttendances);
    }
  };

  const searchAttendances = (input, filterBy = "Agent name") => {
    let filteredAttendances = [];
    switch (filterBy) {
      case "Agent name":
        filteredAttendances = filteredAttendances.filter((attendance) =>
          attendance.name.toLowerCase().includes(input.toLowerCase())
        );
        break;
      case "Process":
      default:
        filteredAttendances = filteredAttendances.filter((attendance) =>
          attendance.process.toLowerCase().includes(input)
        );
        break;
    }
    setSortedAttendances(filteredAttendances);
  };

  const mapAttendancesToTable = (attendances) => {
    return attendances.map((attendance) => {
      return (
        <Table.Row
          key={attendance.ts}
          style={{cursor: "pointer"}}
        >
          <Table.Cell>
            <Icon name="user circle" size="large"/>
          </Table.Cell>
          <Table.Cell>{helperGetDateString(attendance.ts)}</Table.Cell>
          <Table.Cell>{attendance.name}</Table.Cell>
          <Table.Cell>{attendance.process}</Table.Cell>
          <Table.Cell>{attendance.description}</Table.Cell>
          <Table.Cell>{attendance.reason}</Table.Cell>
          <Table.Cell>{helperGetTimeString(attendance.ts)}</Table.Cell>
        </Table.Row>
      );
    });
  };

  const {client} = useContext(ClientContext);

  return (
    <div className="Conversations-Dashboard" style={{marginTop: "70px"}}>
      <div className="attendance__toolbar">
        <DateSelectors
          clickExporData={exportAttendance}
          clickFetchData={ToDateDropdownFunc}
        />
        <div className="search-to-the-right">
          <SearchComponent
            options={searchOptions}
            searchFilter={searchAttendances}
            optionExport={stateOptions[0].value}
          />
        </div>
      </div>
      <Segment raised padded className="segment-style">
        <div className="attendance__top">
          <Header>Attendance</Header>
          <PaginationPageSettings
            items={sortedAttendances}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
          />
        </div>

        <Table basic="very" textAlign="center" selectable unstackable>
          <Table.Header>
            <Table.HeaderCell/>
            <Table.HeaderCell
              onClick={() =>
                orderedByArrow !== "Date"
                  ? setOrderedByArrow("Date")
                  : setOrderedByArrow("Date up")
              }
              style={{cursor: "pointer"}}
            >
              {orderedByArrow === "Date" && <Icon name="caret up"/>}
              {orderedByArrow === "Date up" && <Icon name="caret down"/>}
              Date
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() =>
                orderedByArrow !== "Agent name"
                  ? setOrderedByArrow("Agent name")
                  : setOrderedByArrow("Agent name up")
              }
              style={{cursor: "pointer"}}
            >
              {orderedByArrow === "Agent name" && <Icon name="caret up"/>}
              {orderedByArrow === "Agent name up" && <Icon name="caret down"/>}
              Agent Name
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Dropdown
                trigger={"Process "}
                icon={<Icon name="filter" size="small"/>}
              >
                <Dropdown.Menu>
                  {processOptions.map((option) => (
                    <Dropdown.Item
                      key={option.key}
                      onClick={onChangeMultiselect(setProcessOptions)}
                    >
                      {option.content}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>

            <Table.HeaderCell>
              <Dropdown
                trigger={"Description "}
                icon={<Icon name="filter" size="small"/>}
              >
                <Dropdown.Menu closeOnChange={false}>
                  {descriptionOptions
                    .filter(({processId}) => processOptions.find(({id}) => id === processId).checked)
                    .map((option) => (
                      <Dropdown.Item
                        key={option.key}
                        onClick={onChangeMultiselect(setDescriptionOptions)}
                      >
                        {option.content}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {attendancesByPage &&
              attendancesByPage.length > 0 &&
              mapAttendancesToTable(attendancesByPage)}
          </Table.Body>
        </Table>
        {isLoading && <Loader active inline="centered"/>}
        {!isLoading && sortedAttendances && sortedAttendances.length === 0 && (
          <div style={{textAlign: "center", fontSize: "20px"}}>No Data</div>
        )}
        <Pagination
          pageSize={pageSize}
          items={sortedAttendances || []}
          setItemsByPage={setAttendancesByPage}
          pageNumber={pageNumber}
          onClick={(e) => setPageNumber(Number(e.target.dataset.id))}
        />
      </Segment>
    </div>
  );
};

export default Attendance;
