export const initDescriptionOptions = [
  {
    key: "Agent Connected",
    text: "Agent Connected",
    value: "Agent Connected",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Disconnected",
    text: "Agent Disconnected",
    value: "Agent Disconnected",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Available",
    text: "Agent Available",
    value: "Agent Available",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Unavailable",
    text: "Agent Unavailable",
    value: "Agent Unavailable",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Answered",
    text: "Agent Answered",
    value: "Agent Answered",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Assigned",
    text: "Agent Assigned",
    value: "Agent Assigned",
    checked: true,
    processId: 1,
  },
  {
    key: "User Disconnected Before Answer",
    text: "User Disconnected Before Answer",
    value: "User Disconnected Before Answer",
    checked: true,
    processId: 1,
  },
  {
    key: "User Disconnected",
    text: "User Disconnected",
    value: "User Disconnected",
    checked: true,
    processId: 1,
  },
  {
    key: "User Ended",
    text: "User Ended",
    value: "User Ended",
    checked: true,
    processId: 1,
  },
  {
    key: "User Cancelled",
    text: "User Cancelled",
    value: "User Cancelled",
    checked: true,
    processId: 1,
  },
  {
    key: "Connected Interrupted",
    text: "Connected Interrupted",
    value: "Connected Interrupted",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Invited",
    text: "Agent Invited",
    value: "Agent Invited",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Accepted Invite",
    text: "Agent Accepted Invite",
    value: "Agent Accepted Invite",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Declined Invite",
    text: "Agent Declined Invite",
    value: "Agent Declined Invite",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Cancelled Invite",
    text: "Agent Cancelled Invite",
    value: "Agent Cancelled Invite",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Leave Call",
    text: "Agent Leave Call",
    value: "Agent Leave Call",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Declined Call",
    text: "Agent Declined Call",
    value: "Agent Declined Call",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Finished Call",
    text: "Agent Finished Call",
    value: "Agent Finished Call",
    checked: true,
    processId: 1,
  },
];

export const initProcessOptions = [
  {
    key: "Agent Availability",
    text: "Agent Availability",
    value: "Agent Availability",
    checked: true,
    id: 0,
  },
  {
    key: "Support Call",
    text: "Support Call",
    value: "Support Call",
    checked: true,
    id: 1,
  },
];

export const stateOptions = [
  {
    key: 1,
    text: "Today",
    value: "Today",
  },
  {
    key: 2,
    text: "Last Week",
    value: "Last Week",
  },
  {
    key: 3,
    text: "Last Month",
    value: "Last Month",
  },
];

export const searchOptions = [
  {
    key: "Agent name",
    text: "Agent name",
    value: "Agent name",
  },
];

export const endCallDescriptions = [
  "Agent Unassigned",
  "Agent Finished Call",
  "User Ended",
  "User Cancelled",
  "User Disconnected Before Answer",
];
export const connectionDescriptions = ["Agent Connected", "Agent Disconnected"];
