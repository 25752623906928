import { useState, useEffect, useMemo } from "react";

const useAudio = (
  audioFile,
  options = { loop: false, autoPlay: false, duration: -1 }
) => {
  // Memoize audio object to ensure it is only created once per audioFile change
  const audio = useMemo(() => new Audio(audioFile), [audioFile]);

  const [playing, setPlaying] = useState(false);
  const [sinkId, setSinkId] = useState("");

  const play = () => {
    audio.currentTime = 0;
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
    audio.currentTime = 0;
  };

  // Set sink ID if supported
  useEffect(() => {
    if (audio && audio.setSinkId) {
      audio.setSinkId(sinkId);
    }
  }, [sinkId]);

  // Control audio play/pause
  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing]);

  // Configure audio settings on mount
  useEffect(() => {
    audio.addEventListener("ended", pause);
    audio.ontimeupdate = () => {
      if (options.duration > 0 && audio.currentTime > options.duration) {
        pause();
      }
    };
    audio.loop = options.loop;
    audio.autoplay = options.autoPlay;

    // Clean up on unmount
    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.removeEventListener("ended", pause);
    };
  }, [audio, options.loop, options.autoPlay, options.duration]);

  return { play, pause, playing, setSinkId };
};

export default useAudio;
