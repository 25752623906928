import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import axios from "axios";

import Spinner from "../../components/Spinner/Spinner";
import config from "../../config.json";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";

const Invite = (props) => {
  const env = useContext(EnvContext);
  const { getTokenSilently } = useAuth0();
  const [state, setstate] = useState({
    error: null,
    loading: true,
    redirectUrl: null,
  });
  const params = useParams();

  const supportAPI = axios.create({
    baseURL: config["call-api"][env].replace("ws", "http"),
  });
  useEffect(() => {
    const register = async () => {
      const token = await getTokenSilently();
      const response = await supportAPI.post(
        `/chat/${params.client}/invite/${params.userId}/${params.sessionId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status == 200) {
        setstate({
          loading: false,
          redirectUrl: `/${params.client}/support/${params.userId}/${params.sessionId}`,
        });
      } else {
        setstate({ loading: false, error: response.text });
      }
    };
    if (env && params) {
      register();
    }
  }, [env, params]);

  if (state.loading && !state.error) return <Spinner />;

  if (state.error) return <h1>{state.error}</h1>;

  return <Redirect to={state.redirectUrl} />;
};

export default Invite;
