import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import Spinner from "../../components/Spinner/Spinner";
import config from "../../config.json";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";

const WaitRoom = (props) => {
  const [client, setClient] = useState(null);
  const [meetToken, setMeetToken] = useState(null);
  const env = useContext(EnvContext);
  const [socket, setSocket] = useState(null);
  const { getTokenSilently } = useAuth0();

  const supportAPI = axios.create({
    baseURL: config["call-api"][env].replace("ws", "http"),
  });

  const initSocket = async () => {
    if (socket) {
      socket.onclose = null;
      socket.close();
    }
    const token = await getTokenSilently();
    let connectionSocket = new WebSocket(
      `${config["call-api"][env]}/ws/${client}/RR?token=${token}`
    );
    connectionSocket.onmessage = onMessage;
    connectionSocket.onerror = (e) => initSocket();
    connectionSocket.onclose = (e) => initSocket();
    connectionSocket.onopen = (e) => {
      if (meetToken) {
        fetchConversation(meetToken);
      }
    };
    setSocket(connectionSocket);
  };

  const fetchConversation = async (meetToken) => {
    try {
      const token = await getTokenSilently();
      const conversation = (
        await supportAPI.get(`/chat/${client}/session/byToken`, {
          params: { meetToken },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;

      if (conversation.userId && conversation.session) {
        props.history.push(
          `/${client}/support/${conversation.userId}/${conversation.session}`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onMessage = (e) => {
    const msg = JSON.parse(e.data);
    if (msg.source === "client" && msg.meetToken === meetToken) {
      props.history.push(`/${client}/support/${msg.userId}/${msg.session}`);
    }
  };
  useEffect(() => {
    if (env !== null && client !== null) {
      initSocket();
    }

    if (client == null && props.match.params.client) {
      setClient(props.match.params.client);
    }
    if (meetToken == null && props.match.params.meetToken) {
      setMeetToken(props.match.params.meetToken);
    }

    if (meetToken) {
      fetchConversation(meetToken);
    }
  }, [env, client, meetToken, props]);

  useEffect(() => {
    return () => {
      if (socket !== null) {
        socket.onerror = null;
        socket.onclose = null;
        socket.close();
      }
    };
  }, []);
  return <Spinner text="Waiting for client to connect" />;
};

export default WaitRoom;
