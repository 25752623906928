import React, { useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import axios from "axios";

import config from "../../config.json";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import ProtectedWrapper from "../ProtectedWrapper/ProtectedWrapper";
import Spinner from "../Spinner/Spinner";

const PrivateRoute = ({
  component: Component,
  roles = [],
  permissions = [],
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect, getTokenSilently } =
    useAuth0();
  const env = useContext(EnvContext);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    const path = rest.computedMatch.path;
    if (path.includes("dashboard")) {
      document.title = "Voiceable Dashboard";
    } else if (!path.includes("dashboard") && path.includes(":client")) {
      document.title = "Voiceable Console";
    } else {
      document.title = "Voiceable Console";
    }
    const client = rest.computedMatch.params.client;
    const fetchClient = async () => {
      const token = await getTokenSilently();
      const supportAPI = axios.create({ baseURL: config["api-service"][env] });
      const response = await supportAPI.get(`/configurations/clients/${client}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClientName(response.data.clientName);
    };

    if (client) {
      fetchClient();
    }
  }, [rest.computedMatch.path]);

  useEffect(() => {
    const path = rest.computedMatch.path;

    if (clientName) {
      if (path.includes("dashboard")) {
        document.title = "Voiceable Dashboard - " + clientName;
      } else if (!path.includes("dashboard") && path.includes(":client")) {
        document.title = "Voiceable Console - " + clientName;
      } else {
        document.title = "Voiceable Console";
      }
    }
  }, [clientName]);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect]);

  const render = (props) => {
    if (isAuthenticated) {
      return (
        <ProtectedWrapper roles={roles} permissions={permissions}>
          <Component {...props} />
        </ProtectedWrapper>
      );
    } else {
      return <Spinner />;
    }
  };

  return <Route path={window.location.pathname} render={render} {...rest} />;
};

export default PrivateRoute;
