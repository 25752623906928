import React, { useContext, useEffect, useReducer, useState } from "react";
import ReactPlayer from "react-player";
import Axios from "axios";
import _ from "lodash";
import { Button, Loader } from "semantic-ui-react";
import Cookies from "universal-cookie";

import apiConfig from "../../config.json";
import EnvContext from "../../context/EnvContext";
import LanguageContext from "../../context/LanguageContext";
import { useAuth0 } from "../../react-auth0-spa";
import { safeJsonParse } from "../../utils/helpers";

import baseFieldsConfig from "./config.json";
import { getErrorFields, mapFields } from "./utils";

import "./CallEndedForm.css";
import Chat from "../../views/LiveSupport/Chat/Chat";
import {getChatHistory} from "../../apis/serviceApis";
import ClientContext from "../../context/ClientContext";

const cookies = new Cookies();

const CallEndedForm = ({ setIntent, params, sessionId }) => {
  const [messages, setMessages] = useState([]);
  const [shouldEditMode, setShouldEditMode] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [state, dispatch] = useReducer(reducer, {});
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);
  const { client, market } = useContext(ClientContext);
  const { language, languageDirection } = useContext(LanguageContext);

  const intentBody =
    params.intent &&
    _.omit(
      {
        intentDescription: params.intent.intentDescription || params.intent.description,
        intent: params.intent.intents,
        ...(params.intent.clientIntents || {}),
        ...(params.intent.clientsIntents || {}),
        ...params.intent,
      },
      ["clientIntents", "intents"]
    );

  const editMode = shouldEditMode || !params.intent;
  const environment = useContext(EnvContext);
  const voiceableApiClient = Axios.create({
    baseURL: apiConfig["api"][environment],
  });

  const configLoaded = !_.isEmpty(params.clientConfig);
  const customFields = configLoaded &&
    params.clientConfig &&
    params.clientConfig.callFeedbackFields &&
    Object.keys(params.clientConfig.callFeedbackFields).length > 0;
  const fieldsConfig = customFields ? params.clientConfig.callFeedbackFields : baseFieldsConfig;
  function reducer(state, action) {
    switch (action.type) {
      case "update": {
        const updatedState = { ...state, ...action.payload };
        const updatedFieldNames = Object.keys(action.payload);
        if (errorFields.length) {
          setErrorFields(
            errorFields.filter(
              (errorField) => !updatedFieldNames.includes(errorField)
            )
          );
        }
        const endFormState = safeJsonParse(cookies.get("endFormState"));

        cookies.set(
          "endFormState",
          JSON.stringify({
            ...endFormState,
            [params.id]: updatedState,
          }),
          { path: "/" }
        );
        return updatedState;
      }
      case "init":
        return { ...action.payload };
      default:
        throw new Error();
    }
  }

  useEffect(() => {
    setShouldEditMode(false);
    if (!intentBody) {
      const endFormState = cookies.get("endFormState");
      if (endFormState && endFormState[params.id]) {
        const action = {
          type: "init",
          payload: endFormState[params.id],
        };
        dispatch(action);
      } else {
        const action = {
          type: "init",
          payload: {},
        };
        dispatch(action);
      }
    } else {
      let intent = intentBody;
      if (typeof intent === "string") {
        intent = JSON.parse(intent);
      }
      const action = {
        type: "init",
        payload: intent,
      };
      dispatch(action);
    }
  }, [params.id]);

  // const apiRequestVideo = async () => {
  //   const token = await getTokenSilently();
  //   const sessionId = params.id;
  //   const clientId = params.clientId;
  //   const videoFile = await voiceableApiClient.get(
  //     `/client/${clientId}/${sessionId}/get-video`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json"
  //       },
  //       responseType: "blob",
  //     }
  //   );
  //   return videoFile.data;
  // };

  const submitForm = (e) => {
    e.preventDefault();
    const result = getErrorFields(state, fieldsConfig);
    if (!result.length) {
      setShouldEditMode(false);
      setIntent(state);
      const endFormState = cookies.get("endFormState");
      if (endFormState) {
        const newEndFormState = _.omit(endFormState, params.id);
        cookies.set("endFormState", JSON.stringify({ ...newEndFormState }), {
          path: "/",
        });
      }
    } else {
      setErrorFields(result);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const token = await getTokenSilently();
      const chatHistory = await getChatHistory(token, env, market, sessionId)
      setMessages(chatHistory)
    }
    if (sessionId) {
      fetch()
    }
  }, [sessionId])

  return (
    <form onSubmit={submitForm} className="CallEndedForm">
      <div className="end-form-section">
        <h1>{params.firstPageTitle}</h1>
        <a target="_blank" href={params.firstPage} rel="noreferrer">
          <Button
            type="button"
            icon="compass"
            className={"supportActionButton"}
            content={language.console.callEndedScreen.openPage}
          />
        </a>
        {params.status !== "Missed" && configLoaded && (
          <>
            {mapFields(
              fieldsConfig.customer,
              state,
              dispatch,
              params,
              errorFields,
              editMode
            )}
          </>
        )}
        {
          messages.length ? (
            <Chat
              sendMessage={null}
              chatActive={true}
              handleChatStatus={null}
              messages={messages}
            />
          ) : ""
        }
      </div>
      <div className="end-form-section">
        {params.status !== "Missed" && configLoaded ? (
          <>
            {mapFields(
              fieldsConfig.call,
              state,
              dispatch,
              params,
              errorFields,
              editMode
            )}
            {editMode ? (
              <button
                style={{ direction: languageDirection }}
                className="submit-button"
                type="submit"
              >
                {language.common.submit}
              </button>
            ) : (
              <button
                className="submit-button"
                type="button"
                style={{ direction: languageDirection }}
                onClick={(e) => {
                  e.preventDefault();
                  setShouldEditMode(true);
                }}
              >
                {language.common.edit}
              </button>
            )}
          </>
        ) : (
          <Loader enabled="true" size="medium" />
        )}
        <div className="enterer" />
        <div className="enterer" />
      </div>
    </form>
  );
};

export default CallEndedForm;
